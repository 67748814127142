/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    p: "p",
    a: "a",
    pre: "pre",
    code: "code"
  }, _provideComponents(), props.components), {Aside} = _components;
  if (!Aside) _missingMdxReference("Aside", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.h2, {
    id: "day-1-calorie-counting"
  }, "Day 1: Calorie Counting"), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://adventofcode.com/2022/day/1"
  }, "https://adventofcode.com/2022/day/1")), "\n", React.createElement(Aside, null, React.createElement(_components.p, null, "TL;DR: ", React.createElement(_components.a, {
    href: "https://github.com/NickyMeuleman/scrapyard/blob/main/advent_of_code/2022/src/day_01.rs"
  }, "my solution in Rust"))), "\n", React.createElement(_components.p, null, "We’re on an expedition with the elves.\nThe input file represents the amount of calories each elf carries with them.\nEach elf has several items.\nThe inventories of each elf is seperated by two line breaks."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-txt",
    title: "input.txt"
  }, "1000\n2000\n3000\n\n4000\n\n5000\n6000\n\n7000\n8000\n9000\n\n10000\n")), "\n", React.createElement(_components.h2, {
    id: "part-1"
  }, "Part 1"), "\n", React.createElement(_components.p, null, "Find the Elf carrying the most Calories.\nHow many total Calories is that Elf carrying?"), "\n", React.createElement(_components.p, null, "For each elf we want to calculate the sum of the items they are carrying.\nThen, find the largest of those sums."), "\n", React.createElement(_components.p, null, "I did today with one iterator chain."), "\n", React.createElement(_components.p, null, "Get the input string.\nSplit it on double newlines.\nEach iterator element is now a string that represents the inventory of a single elf."), "\n", React.createElement(_components.p, null, "For every element: split on a single linebreak.\nTurn that line into a number.\nTake the sum of every number."), "\n", React.createElement(_components.p, null, "Now, every element is the sum a single elf is carrying.\nFind the maximum of those sums."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust",
    title: "day_01.rs"
  }, "pub fn part_1() -> u32 {\n    let input = std::fs::read_to_string(\"src/day01.txt\").unwrap();\n\n    input\n    .split(\"\\n\\n\")\n    .map(|elf| {\n        elf.lines()\n            .filter_map(|s| s.parse::<u32>().ok())\n            .sum::<u32>()\n    })\n    .max()\n    .unwrap()\n}\n")), "\n", React.createElement(_components.h2, {
    id: "part-2"
  }, "Part 2"), "\n", React.createElement(_components.p, null, "Part 2 is very similar."), "\n", React.createElement(_components.p, null, "Find the top three Elves carrying the most Calories.\nHow many Calories are those Elves carrying in total?"), "\n", React.createElement(_components.p, null, "Get the input string.\nSplit it on double newlines.\nEach iterator element is now a string that represents the inventory of a single elf."), "\n", React.createElement(_components.p, null, "For every element: split on a single linebreak.\nTurn that line into a number.\nTake the sum of every number."), "\n", React.createElement(_components.p, null, "Now, every element is the sum a single elf is carrying.\nSort those sums.\nTake the 3 largest sums, and sum those."), "\n", React.createElement(_components.p, null, "To sort the iterator directly, I used the ", React.createElement(_components.code, null, "sorted()"), " method from the ", React.createElement(_components.code, null, "itertools"), " crate.\nI could have also called ", React.createElement(_components.code, null, ".collect()"), " on the iterator of all sums, and store that result in a variable with type ", React.createElement(_components.code, null, "Vec<u32>"), ".\nThen sort that resulting vector with ", React.createElement(_components.code, null, ".sort()"), "."), "\n", React.createElement(_components.p, null, "This sorts in ascending order, and I want the sum of the 3 last ones.\nSo I reverse the iterator.\nTake 3 items of that iterator.\nSum those."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust",
    title: "day_01.rs"
  }, "use itertools::Itertools;\n\npub fn part_2() -> u32 {\n    let input = std::fs::read_to_string(\"src/day01.txt\").unwrap();\n\n    input\n    .split(\"\\n\\n\")\n    .map(|elf| {\n        elf.lines()\n            .filter_map(|s| s.parse::<u32>().ok())\n            .sum::<u32>()\n    })\n    .sorted()\n    .rev()\n    .take(3)\n    .sum::<u32>()\n}\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
